import React, { useState } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ButtonWorkFilter from "../components/ButtonWorkFilter";
import ProjectsComp from "../components/ProjectsComp";

const Work = () => {
  const [selectedTag, setSelectedTag] = useState("all");
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleTagSelection = (tag: string) => {
    setSelectedTag(tag);
  };


  const buttonStates = ["all", "public", "private"];

  return (
    <>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        color="inherit"
        style={{ marginBottom: "40px" }}
      >
        {buttonStates.map((tag) => (
          <ButtonWorkFilter
            key={tag}
            selectedTag={selectedTag}
            tag={tag}
            handleTagSelection={handleTagSelection}
          />
        ))}
      </Grid>
   
      <ProjectsComp selectedTag={selectedTag} />
    </>
  );
};

export default Work;
