import React from "react";
import { Box, Card, CardMedia, Link, useMediaQuery, useTheme  } from "@mui/material";

interface ClientCardProps {
  imageUrl: string;
  websiteUrl: string;
}

const ClientCard: React.FC<ClientCardProps> = ({ imageUrl, websiteUrl }) => {
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card sx={{ maxWidth: "100%", m: "auto", mb: isMobile ? 3 : 0 , mx: isMobile ? 1 : 0}}>
      <Link href={websiteUrl} target="_blank" rel="noopener noreferrer">
        <CardMedia component="img" image={imageUrl} alt={websiteUrl} />
      </Link>
    </Card>
  );
};

export default ClientCard;
