import React from "react";
import Banner from "../components/banner";
import banners from "../data/banners";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import Map from "../components/map";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import ContactUs from "../components/ContactUs";

const Contact = () => {
  return (
    <>
      <Grid item xs={12}>
        <Box id={banners.contact.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.contact.image}
            text={banners.contact.title}
          />
        </Box>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          pt: { xs: 2, md: 4 }, // Adds padding-top space with different values for xs and md breakpoints
          pb: { xs: 2, md: 4 }, // Adds padding-bottom space with different values for xs and md breakpoints
        }}
      >
        <Grid item xs={12} md={6}>
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center" // Center vertically
    alignItems={{ xs: "center", md: "center" }} // Center on mobile, left on desktop
    height="100%"
    textAlign={{ xs: "center", md: "center" }} // Center text alignment on mobile, left on desktop
  >
    <div>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 900 }}>
        Gheorghe Lazar 21 Str.Bucharest, Romania
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 900 }}>
        PO 010201
      </Typography>
      <Box
        display="flex"
        alignItems={{ xs: "center", md: "center" }}
        justifyContent="center"
        gap={1}
        mb={2}
        textAlign={{ xs: "center", md: "left" }}
      >
        
        <PhoneIcon color="action" fontSize="large" />
        <Typography variant="h5" sx={{ fontWeight: 900, color: "grey" }}  textAlign={{ xs: "center", md: "left" }} >
          <a href="tel:+40732771959" style={{ textDecoration: "none", color: "inherit" }}>
            0732 771 959
          </a>
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "center", md: "flex-start" }}
        gap={1}
        mb={2}
      >
        <WhatsAppIcon color="action" fontSize="large" />
        <Typography variant="h5" sx={{ fontWeight: 900, color: "grey" }}>
          <a
            href="https://wa.me/40730617496"
            style={{ textDecoration: "none", color: "inherit" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            0730 617 496
          </a>
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems={{ xs: "center", md: "flex-start" }}
        justifyContent="center"
        gap={1}
        mb={2}
      >
        <EmailIcon color="action" fontSize="large" />
        <Typography variant="h5" sx={{ fontWeight: 900, color: "grey" }}>
          <a href="mailto:home@triptic.ro" style={{ textDecoration: "none", color: "inherit" }}>
            home@triptic.ro
          </a>
        </Typography>
      </Box>
    </div>
  </Box>
</Grid>

        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center vertically
            alignItems="center" // Center horizontally
            height="100%"
            textAlign="center" // Center text alignment
          >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 900 }}>
              Get in Touch
            </Typography>
            <Grid container justifyContent="center">
              {/* Grid item with specified columns */}
              <Grid item xs={12} md={8}>
                <ContactUs />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          sx={{
            pt: 6,
          }}
        >
          {/* <Typography variant="h4" gutterBottom sx={{ fontWeight: 900 }}>
            Our Location
          </Typography> */}
          <Map />
        </Box>
      </Grid>
    </>
  );
};

export default Contact;
