import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import {MarkerF} from '@react-google-maps/api'

const containerStyle = {
  width: "100%",
  height: "500px",
};

// Use the provided latitude and longitude values
const center = {
  lat: 44.43807,
  lng: 26.08716,
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Map: React.FC = () => {
  return (
    <LoadScript googleMapsApiKey={apiKey || ""}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        {/* <Marker position={center} key={`${center.lat}-${center.lng}`} /> */}
        <MarkerF position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
