// ClientsList.tsx
import React from "react";
import { Grid } from "@mui/material";
import ClientCard from "./ClientCard";
import clientsData from "../data/clientsData";

// Define the interface for the client object
interface Client {
  imageUrl: string;
  websiteUrl: string;
}

const ClientsList: React.FC = () => {
  return (
    <>
    <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          {/* Left column (empty) */}
        </Grid>

         <Grid item xs={12} md={8}>
            <Grid container spacing={0}>
              {clientsData.map((client: Client, index: number) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} xl={1.5} key={index}>
                    <ClientCard
                      imageUrl={client.imageUrl}
                      websiteUrl={client.websiteUrl}
                    />
                  </Grid>
              ))}
            </Grid>
          </Grid>


          <Grid item xs={12} md={2}>
            {/* Left column (empty) */}
          </Grid>
    </Grid>
    {/* <Grid container spacing={0}>
      
      {clientsData.map((client: Client, index: number) => (
        <Grid item xs={12} sm={4} md={3} lg={2} xl={1.5} key={index}>
       
          <ClientCard
            imageUrl={client.imageUrl}
            websiteUrl={client.websiteUrl}
          />
        </Grid>
      ))}
    </Grid> */}
    </>
  );
};

export default ClientsList;
