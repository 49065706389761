import React, { useState } from "react";
import projects from "../data/projects";
import Project from "./Project";
import {
  Grid,
  Button,
  Select,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ButtonWorkFilter from "./ButtonWorkFilter";

const ProjectsComp = ( {selectedTag = "all"}) => {
  //const [selectedTag, setSelectedTag] = useState("all");
  const theme = useTheme(); // Get the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));



  // const handleSelectChange = (event: SelectChangeEvent<string>) => {
  //   setSelectedTag(event.target.value);
  // };

  const filteredProjects = selectedTag === "all"
    ? projects
    : projects.filter((project) => project.tag === selectedTag);

  // Sort projects in descending order based on _id
  const sortedProjects = filteredProjects.sort((a, b) => b._id.localeCompare(a._id));
  return (
    <>
      
      <Grid container spacing={3} className="mt-4 pt-4">
        {sortedProjects.map((project) => (
          <Grid item key={project._id} xs={12} sm={6} md={4}>
            <Project project={project} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProjectsComp;

