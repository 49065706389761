import images from "../data/projectImages";

const projects = [
  {
    _id: "1",
    name: "CPM CONSTANTA EXTENSION",
    url: "CPM_CT_retail_2016_01",
    image: images.CPM_CT_retail_2016_01[0],
    tag: "private",
    carousel: images.CPM_CT_retail_2016_01.map((img) => ({ imgPath: img })),
    description: "",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Retail",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "35,000 sqm",
    year: "2016",
  },
  {
    _id: "2",
    name: "AF APARTMENTS",
    url: "Avangarde_rezi_2017_01",
    image: images.Avangarde_rezi_2017_01[0],
    tag: "private",
    carousel: images.Avangarde_rezi_2017_01.map((img) => ({ imgPath: img })),
    description: "",
    location: "Voluntari, Romania",
    client: "Private",
    function: "Residential",
    projectPhase: "",
    status: "Proposal",
    programArea: "4500 sqm",
    year: "2017",
  },
  {
    _id: "3",
    name: "WY LOGISTIC PARK",
    url: "WY_industrial_2017_02",
    image: images.WY_industrial_2017_02[0],
    tag: "private",
    carousel: images.WY_industrial_2017_02.map((img) => ({ imgPath: img })),
    description: "",
    location: "Bucharest, Romania",
    client: "E-RES REAL ESTATE & ENERGY",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "4",
    name: "STUDENT HOUSING BUCHAREST",
    url: "SHB_rezi_2018_01",
    image: images.SHB_rezi_2018_01[0],
    tag: "private",
    carousel: images.SHB_rezi_2018_01.map((img) => ({ imgPath: img })),
    description: "",
    location: "Bucharest, Romania",
    client: "Private",
    function: "Student Housing",
    projectPhase: "Concept",
    status: "Contest – 1st Prize",
    programArea: "9800  sqm",
    year: "2018",
  },
  {
    _id: "5",
    name: "Penny Retail",
    url: "Penny_retail_2019_02",
    image: images.Penny_retail_2019_02[0],
    tag: "private",
    carousel: images.Penny_retail_2019_02.map((img) => ({ imgPath: img })),
    description: "",
    location: "Romania",
    client: "Penny",
    function: "Logistic & Industrial",
    projectPhase: "",
    status: "Completed",
    programArea: "",
    year: "",
  },
  {
    _id: "6",
    name: "ELEMASTER FACTORY",
    url: "Elemaster_industrial_2019_03",
    image: images.Elemaster_industrial_2019_03[0],
    tag: "private",
    carousel: images.Elemaster_industrial_2019_03.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Arges, Romania",
    client: "ELEMASTER ROMANIA",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "10,500  sqm",
    year: "2019",
  },
  {
    _id: "7",
    name: "Mamaia Public",
    url: "Mamaia_public_2020_01",
    image: images.Mamaia_public_2020_01[0],
    tag: "public",
    carousel: images.Mamaia_public_2020_01.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Constanta, Romania",
    client: "",
    function: "public",
    projectPhase: "",
    status: "",
    programArea: "",
    year: "",
  },
  {
    _id: "8",
    name: "PROMENADA CONSTANTA",
    url: "Peninsula_public_2020_02",
    image: images.Peninsula_public_2020_02[0],
    tag: "public",
    carousel: images.Peninsula_public_2020_02.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Constanta, Romania",
    client: "World Bank & Municipality of Constanta County",
    function: "Urban Design",
    projectPhase: "",
    status: "Proposal",
    programArea: "",
    year: "2019",
  },
  {
    _id: "9",
    name: "MAIN SQUARE MAMAIA RESORT",
    url: "Mamaia_public_2020_03",
    image: images.Mamaia_public_2020_03[0],
    tag: "public",
    carousel: images.Mamaia_public_2020_03.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Mamaia, Romania",
    client: "World Bank & Municipality of Constanta County",
    function: "Urban Design",
    projectPhase: "",
    status: "Proposal",
    programArea: "10,000  sqm",
    year: "2020",
  },
  {
    _id: "10",
    name: "A.B. House",
    url: "Bucur_House_rezi_2021_01",
    image: images.Bucur_House_rezi_2021_01[0],
    tag: "private",
    carousel: images.Bucur_House_rezi_2021_01.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Bucharest, Romania",
    client: "Private",
    function: "Residential",
    projectPhase: "",
    status: "In Progress",
    programArea: "360 sqm",
    year: "2021",
  },
  {
    _id: "11",
    name: "Maserati Office",
    url: "Maserati_office_2021_02",
    image: images.Maserati_office_2021_02[0],
    tag: "private",
    carousel: images.Maserati_office_2021_02.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Romania",
    client: "Maserati",
    function: "Private",
    projectPhase: "",
    status: "",
    programArea: "",
    year: "",
  },
  {
    _id: "12",
    name: "SECURITY CENTER",
    url: "Sibiu_acces_public_2021_03",
    image: images.Sibiu_acces_public_2021_03[0],
    tag: "public",
    carousel: images.Sibiu_acces_public_2021_03.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Sibiu, Romania",
    client: "Private",
    function: "Services",
    projectPhase: "",
    status: "",
    programArea: "75,000  sqm",
    year: "2021",
  },
  {
    _id: "13",
    name: "FIRE DEPARTMENT",
    url: "Sibiu_Remiza_public_2021_04",
    image: images.Sibiu_Remiza_public_2021_04[0],
    tag: "public",
    carousel: images.Sibiu_Remiza_public_2021_04.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Sibiu, Romania",
    client: "Private",
    function: "Services",
    projectPhase: "",
    status: "",
    programArea: "230,000  sqm",
    year: "2021",
  },
  {
    _id: "14",
    name: "153 SCHOOL YARD REGENERATION",
    url: "Curte_scoala_public_2022_01",
    image: images.Curte_scoala_public_2022_01[0],
    tag: "public",
    carousel: images.Curte_scoala_public_2022_01.map((img) => ({
      imgPath: img,
    })),
    description: "",
    location: "Bucharest, Romania",
    client: "World Bank & Municipality of Bucharest - S6",
    function: "Educational",
    projectPhase: "",
    status: "In Progress",
    programArea: "18,000  sqm",
    year: "2022",
  },
  {
    _id: "15",
    name: "Harman House",
    url: "Harman_House_rezi_2022_02",
    image: images.Harman_House_rezi_2022_02[0],
    tag: "private",
    carousel: images.Harman_House_rezi_2022_02.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "16",
    name: "CRP",
    url: "CRP_retail_2022_03",
    image: images.CRP_retail_2022_03[0],
    tag: "private",
    carousel: images.CRP_retail_2022_03.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "17",
    name: "UPB1",
    url: "UPB1_public_2023_01",
    image: images.UPB1_public_2023_01[0],
    tag: "public",
    carousel: images.UPB1_public_2023_01.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "18",
    name: "Cresa",
    url: "Cresa_public_2023_02",
    image: images.Cresa_public_2023_02[0],
    tag: "public",
    carousel: images.Cresa_public_2023_02.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "19",
    name: "Stadion Selimbar",
    url: "Stadion_Selimbar_public_2023_03",
    image: images.Stadion_Selimbar_public_2023_03[0],
    tag: "private",
    carousel: images.Stadion_Selimbar_public_2023_03.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2018",
  },
  {
    _id: "20",
    name: "Stadion Brasov",
    url: "Stadion_Bv_public_2023_04",
    image: images.Stadion_Bv_public_2023_04[0],
    tag: "private",
    carousel: images.Stadion_Bv_public_2023_04.map((img) => ({
      imgPath: img,
    })),
    description: "AF APARTMENTS",
    location: "Constanta, Romania",
    client: "NEPI",
    function: "Logistic & Industrial",
    projectPhase: "Concept",
    status: "Completed",
    programArea: "18,000  sqm",
    year: "2023",
  },
];

export default projects;
