import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery"; 
import { useTheme } from "@mui/material/styles"; 
import logoTriptic from "../assets/logoTriptic.png";

import ProjectsComp from "../components/ProjectsComp";

const pages = ["work", "office", "contact"];

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  const handleToggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActiveLink = (page: string) => {
    return location.pathname === `/${page}`;
  };

  return (
    <>
      {!isMobile && (
        <ProjectsComp />
      )}
{isMobile && (
  <Box
    sx={{
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "white",
      zIndex: theme.zIndex.modal,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", // Center content vertically
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        py: 2,
      }}
    >
      <Link to="/" style={{ textDecoration: "none" }}>
        <Box
          component="img"
          src={logoTriptic}
          alt="TRIPTIC Logo"
          sx={{
            maxWidth: "180px",
          }}
        />
      </Link>
    </Box>

    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column", // Stack buttons vertically
        alignItems: "center",
        mt: 2,
      }}
    >
      {pages.map((page) => (
        <Button
          key={page}
          component={Link}
          to={`/${page}`}
          sx={{
            my: 2,
            fontFamily: "monospace",
            fontWeight: 700,
            fontSize: "40px",
            letterSpacing: "0px",
            textTransform: "uppercase",
            lineHeight: "100%",
            color: isActiveLink(page) ? "black" : "#888",
            textDecoration: "none",
            transition: "color .24s ease-out",
            "&:hover": {
              color: "black",
            },
          }}
        >
          {page}
        </Button>
      ))}
    </Box>
  </Box>
)}


    </>
  );
};

export default Home;
